import { createAsyncThunk, createSlice, createAction } from "@reduxjs/toolkit";
import axios from "axios";
import {
  API_CREATEPOST_URL,
  API_DELETEPOST_URL,
  API_DOMAIN_URL,
  API_GETALLPOSTS_URL,
  API_TOGGLEDISLIKE_URL,
  API_TOGGLELIKE_URL,
  API_UPDATEPOST_URL,
} from "../../../utility/backendAPILinks";

//Action to redirect
const resetPost = createAction("category/reset");

const resetPostEdit = createAction("post/reset");

const resetPostDelete = createAction("post/delete");

//Create Post Action
export const createPostAction = createAsyncThunk(
  "post/created",
  async (post, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;

    const { userAuth } = user;

    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    try {
      //http call
      const formData = new FormData();
      formData.append("title", post?.title);
      formData.append("description", post?.description);
      formData.append("category", post?.category);
      formData.append("image", post?.image);

      const { data } = await axios.post(
        `${API_DOMAIN_URL}/${API_CREATEPOST_URL}`,
        formData,
        config
      );

      ///dispatch action
      dispatch(resetPost());

      return data;
    } catch (error) {
      if (!error?.response) throw error;

      return rejectWithValue(error?.response?.data);
    }
  }
);

//Update a post
export const updatePostAction = createAsyncThunk(
  "post/updated",
  async (post, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;

    const { userAuth } = user;

    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    try {
      //http call

      const { data } = await axios.put(
        `${API_DOMAIN_URL}/${API_UPDATEPOST_URL}/${post?.id}`,
        post,
        config
      );

      dispatch(resetPostEdit());

      return data;
    } catch (error) {
      if (!error?.response) throw error;

      return rejectWithValue(error?.response?.data);
    }
  }
);

//Delete a post
export const deletePostAction = createAsyncThunk(
  "post/delete",
  async (postId, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;

    const { userAuth } = user;

    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    try {
      //http call

      const { data } = await axios.delete(
        `${API_DOMAIN_URL}/${API_DELETEPOST_URL}/${postId}`,
        config
      );

      //dispatch
      dispatch(resetPostDelete());

      return data;
    } catch (error) {
      if (!error?.response) throw error;

      return rejectWithValue(error?.response?.data);
    }
  }
);

//Fetch All posts
export const fetchAllPostsAction = createAsyncThunk(
  "post/list",
  async (category, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;

    try {
      const { data } = await axios.get(
        `${API_DOMAIN_URL}/${API_GETALLPOSTS_URL}?category=${category}`
      );

      return data;
    } catch (error) {
      if (!error?.response) throw error;

      return rejectWithValue(error?.response?.data);
    }
  }
);

//Fetch post details
export const fetchPostDetailsAction = createAsyncThunk(
  "post/details",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(
        `${API_DOMAIN_URL}/${API_GETALLPOSTS_URL}/${id}`
      );

      return data;
    } catch (error) {
      if (!error?.response) throw error;

      return rejectWithValue(error?.response?.data);
    }
  }
);

//Add like to post
export const toggleLikeToPost = createAsyncThunk(
  "post/like",
  async (postId, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;

    const { userAuth } = user;

    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };

    try {
      const { data } = await axios.put(
        `${API_DOMAIN_URL}/${API_TOGGLELIKE_URL}`,
        { postId },
        config
      );
      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);

//Add dislike to post
export const toggleDislikeToPost = createAsyncThunk(
  "post/dislike",
  async (postId, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;

    const { userAuth } = user;

    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };

    try {
      const { data } = await axios.put(
        `${API_DOMAIN_URL}/${API_TOGGLEDISLIKE_URL}`,
        { postId },
        config
      );
      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);

//slice
const postSlice = createSlice({
  name: "post",
  initialState: {},
  extraReducers: (builder) => {
    //create post
    builder.addCase(createPostAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(resetPost, (state, action) => {
      state.isCreated = true;
    });
    builder.addCase(createPostAction.fulfilled, (state, action) => {
      state.postCreated = action?.payload;
      state.loading = false;
      state.isCreated = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createPostAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

    //update post
    builder.addCase(updatePostAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(resetPostEdit, (state, action) => {
      state.isUpdated = true;
    });

    builder.addCase(updatePostAction.fulfilled, (state, action) => {
      state.postUpdated = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
      state.isUpdated = false;
    });
    builder.addCase(updatePostAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

    //delete post
    builder.addCase(deletePostAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(resetPostDelete, (state, action) => {
      state.isDeleted = true;
    });

    builder.addCase(deletePostAction.fulfilled, (state, action) => {
      state.postUpdated = action?.payload;
      state.isDeleted = false;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });

    builder.addCase(deletePostAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

    //fetch all posts
    builder.addCase(fetchAllPostsAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(fetchAllPostsAction.fulfilled, (state, action) => {
      state.postLists = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(fetchAllPostsAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

    //fetch post details
    builder.addCase(fetchPostDetailsAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(fetchPostDetailsAction.fulfilled, (state, action) => {
      state.postDetails = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(fetchPostDetailsAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

    //toggle likes
    builder.addCase(toggleLikeToPost.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(toggleLikeToPost.fulfilled, (state, action) => {
      state.likes = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(toggleLikeToPost.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

    //toggle dislikes
    builder.addCase(toggleDislikeToPost.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(toggleDislikeToPost.fulfilled, (state, action) => {
      state.dislikes = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(toggleDislikeToPost.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
  },
});

export default postSlice.reducer;

import React from "react";
import poster from "../../img/rx.png";

function getCurrentYear() {
  var dt = new Date();
  return dt.getFullYear();
}
const HomePage = () => {
  return (
    <>
      <section className="pb-10 bg-black min-h-screen">
        <div className="relative container px-4 mx-auto">
          <div className="flex flex-wrap items-center -mx-4 mb-10 2xl:mb-14">
            <div className="w-full lg:w-1/2 px-4 mb-16 lg:mb-0">
              <h2 className="max-w-2xl mt-12 mb-12 text-6xl 2xl:text-8xl text-white font-bold font-heading">
                Welcome to my blog{" "}
                <span>
                  <font color="#1099cc">Thayiland</font>
                </span>
              </h2>
              <p className="mb-12 lg:mb-16 2xl:mb-24 text-xl text-gray-100">
                Sharing my{" "}
                <span className="text-red-400 hover:underline">
                  <a href="/posts">thoughts</a>
                </span>{" "}
                one post at a time.{" "}
                {/* <span className="text-red-400">unhealthy words</span>. */}
              </p>
            </div>
            <div className="w-full lg:w-1/2 px-4">
              <img className="w-full" src={poster} alt={poster} />
            </div>
          </div>
        </div>

        {/* Copyright*/}
        <div className="text-center text-red-400 mt-10">
          {" "}
          Copyright &copy; {getCurrentYear()} by Thayiland <br /> All rights
          reserved
        </div>
      </section>
    </>
  );
};

export default HomePage;

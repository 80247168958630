import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

export default function AccountVerificationSuccessAlert() {
  return (
    <div className="rounded-md bg-green-600 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <FontAwesomeIcon
            icon={faCheckCircle}
            className="h-5 w-5 text-green-50"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <p className="text-sm font-medium text-green-50">
            Email is successfully sent to your Email
          </p>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllUsersAction } from "../../../redux/slices/users/usersSlices";
import LoadingSpinner from "../../../utility/LoadingSpinner";
import UsersListHeader from "./UsersListHeader";
import UsersListItem from "./UsersListItem";

const UsersList = () => {
  //dispatch
  const dispatch = useDispatch();

  //data from store
  const users = useSelector((state) => state?.users);
  const {
    usersList,
    appErr,
    serverErr,
    loading,
    block,
    unblock,
    deleted,
  } = users;

  //fetch all users
  useEffect(() => {
    dispatch(fetchAllUsersAction());
  }, [block, unblock, deleted]);

  return (
    <>
      <section className="py-8 bg-black min-h-screen">
        <UsersListHeader className="mt-4" />
        {loading ? (
          <>
            <h1 className="text-lg text-white text-center">
              Loading please wait...
            </h1>
            <LoadingSpinner />
          </>
        ) : appErr || serverErr ? (
          <h2 className="text-center text-xl text-red-500">
            {appErr} {serverErr}
          </h2>
        ) : usersList?.length == 0 ? (
          <h2>No user found</h2>
        ) : (
          usersList?.map((user) => (
            <div key={user._id}>
              <div className="container px-4 mx-auto">
                <UsersListItem user={user} />
              </div>
            </div>
          ))
        )}
      </section>
    </>
  );
};

export default UsersList;

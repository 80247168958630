import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHammer,
  faHeart,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

const serviceMail = "thayilakshmidevi@gmail.com";
const supportMail = "thayilandinfo@gmail.com";
const developerWebsite = "https://www.anugrahkumar.com";
const patreonLink = "";

const AboutMe = () => {
  return (
    <div className="h-screen bg-black text-white text-center text-xl pt-7">
      <h1 className="">
        <b className="text-2xl underline">About me</b>
      </h1>
      <br />
      <p className="pl-3 pr-3 justify-center">
        Hi, this is <font color="#1099cc">Thayi Lakshmi</font>. Welcome to my
        blog where I share my love for all things spiritual, vedic astrology,
        self healing and more <FontAwesomeIcon color="pink" icon={faHeart} />.
      </p>
      <br />
      <br />

      <h1 className="">
        <b className="text-2xl underline">Get in touch</b>
      </h1>
      <div className="pt-3 lg:text-center pl-3 pr-3 lg:pl-5 lg:pr-5">
        <b>
          <font color="#fe6464">
            <u> Services</u>{" "}
          </font>
        </b>
        <br />
        <FontAwesomeIcon color="white" icon={faEnvelope} /> {serviceMail}
        <br />
        <br />
        <b>
          <font color="#fe6464">
            <u> Support</u>{" "}
          </font>
        </b>
        <br />
        <FontAwesomeIcon color="white" icon={faEnvelope} /> {supportMail}
        <br />
      </div>
      <div className="pt-5">
        <p>
          You can support me on patreon{" "}
          <font color="#1099cc" className="hover:underline">
            <a href={patreonLink}>link </a>
          </font>
          {patreonLink == "" ? (
            <>
              <FontAwesomeIcon icon={faHammer} />
              <font className="text-gray-500 text-sm">
                {" "}
                (Under construction){" "}
              </font>
            </>
          ) : null}{" "}
        </p>
      </div>
      <div className="pt-10 text-gray-500">
        <i>
          {" "}
          <p className="">(Developed by </p>{" "}
          <a
            className="text-red-400 hover:text-cyan-500 hover:underline"
            href={developerWebsite}
          >
            Anu
          </a>
          )
        </i>
      </div>
    </div>
  );
};

export default AboutMe;

import React from "react";
import poster from "../../../img/rx.png";
const UsersListHeader = (users) => {
  return (
    <div className=" relative bg-black">
      <div className="absolute inset-0">
        <img className="w-full h-full object-cover" src={poster} alt="" />
        <div
          className="absolute inset-0 bg-gray-600 mix-blend-multiply"
          aria-hidden="true"
        />
      </div>
      <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">
          Authors List - {users?.users?.length}
        </h1>
      </div>
    </div>
  );
};

export default UsersListHeader;

import { useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faUser,
  faArrowUpFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { userProfileAction } from "../../../redux/slices/users/usersSlices";
import { useDispatch, useSelector } from "react-redux";
import banner from "../../../img/profileBanner.png";
import DateFormatter from "../../../utility/DateFormatter";
import LoadingSpinner from "../../../utility/LoadingSpinner";

export default function Profile(props) {
  const id = useParams().id;

  //History
  const history = useNavigate();

  const dispatch = useDispatch();
  //fetch user profile
  useEffect(() => {
    dispatch(userProfileAction(id));
  }, [id, dispatch]);

  //User data from store
  const users = useSelector((state) => state.users);

  const { profile, loading, appErr, serverErr, userAuth } = users;

  //send Email Handle click
  const sendMailNavigate = () => {
    history("/send-email", {
      state: { email: profile?.email, id: profile?._id },
    });
  };

  return (
    <>
      <div className="min-h-screen bg-black justify-center items-center">
        {loading ? (
          <LoadingSpinner />
        ) : appErr || serverErr ? (
          <h1 className="text-center text-red-500 text-3xl">
            {appErr} {serverErr}
          </h1>
        ) : (
          <div className="min-h-screen min-w-screen flex overflow-hidden bg-white">
            {/* Static sidebar for desktop */}

            <div className="flex flex-col min-w-0 flex-1 overflow-hidden">
              <div className="flex-1 relative z-0 flex overflow-hidden">
                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
                  <article>
                    {/* Profile header */}
                    <div>
                      <div>
                        <img
                          className="h-32 w-full object-cover lg:h-48"
                          src={banner}
                          alt={profile?.fullName}
                        />
                      </div>
                      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
                          <div className="flex -mt-20">
                            <img
                              className="h-24 w-24 rounded-full  ring-4 ring-white sm:h-32 sm:w-32"
                              src={profile?.profilePhoto}
                              alt={profile?.fullName}
                            />
                          </div>
                          <div className="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                            <div className=" flex flex-col 2xl:block mt-10 min-w-0 flex-1">
                              <h1 className="text-2xl font-bold text-gray-900 ">
                                {profile?.firstName} {profile?.lastName}
                                {profile?.isAdmin ? (
                                  <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-yellow-100 text-yellow-800">
                                    <p>Admin</p>
                                  </span>
                                ) : null}
                                {/* Display if verified or not */}
                                {profile?.isAccountVerified ? (
                                  <span className="inline-flex ml-2 items-center px-3 py-0.5  rounded-lg text-sm font-medium bg-green-600 text-gray-300">
                                    Account Verified
                                  </span>
                                ) : (
                                  <span className="inline-flex ml-2 items-center px-3 py-0.5  rounded-lg text-sm font-medium bg-red-600 text-gray-300">
                                    Unverified Account
                                  </span>
                                )}
                              </h1>
                              <p className="m-3 text-lg">
                                Date Joined:{" "}
                                <DateFormatter date={profile?.createdAt} />
                              </p>
                              <p className="text-green-700 mt-2 mb-2">
                                {profile?.posts.length} posts have been
                                published{" "}
                              </p>

                              {/* is login user */}
                              {/* Upload profile photo */}
                              {profile?._id == userAuth?._id ? (
                                <Link
                                  to={`/upload-photo/${profile?._id}`}
                                  className="inline-flex justify-center px-4 py-2 mt-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                                >
                                  {/* <UploadIcon
                              className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            /> */}

                                  <FontAwesomeIcon
                                    icon={faArrowUpFromBracket}
                                    className="-ml-1 mr-2  h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  <span className="text-base mr-2  text-bold ">
                                    Upload Photo
                                  </span>
                                </Link>
                              ) : null}
                            </div>

                            {/* Update Profile */}
                            {profile?._id == userAuth?._id ? (
                              <Link
                                to={`/update-profile/${profile?._id}`}
                                className="inline-flex justify-center px-4 py-2 mt-4 border shadow-sm text-sm font-medium rounded-md text-gray-700 bg-red-400 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                              >
                                <FontAwesomeIcon
                                  icon={faUser}
                                  className="-ml-1 mr-2  h-5 w-5 text-gray-200"
                                  aria-hidden="true"
                                />
                                <span className="text-base mr-2  text-bold text-white">
                                  Update Profile
                                </span>
                              </Link>
                            ) : null}

                            <br />
                            {/* Send Mail */}
                            <button
                              onClick={sendMailNavigate}
                              className="inline-flex justify-center bg-cyan-400 px-4 py-2 mt-4 border shadow-sm text-sm font-medium rounded-md text-gray-700  hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                            >
                              {/* <MailIcon
                              className="-ml-1 mr-2 h-5 w-5 text-gray-200"
                              aria-hidden="true"
                            /> */}
                              <FontAwesomeIcon
                                icon={faEnvelope}
                                className="-ml-1 mr-2 h-5 w-5 text-gray-200"
                              />
                              <span className="text-base mr-2  text-bold text-white">
                                Send Message
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Tabs */}
                    <div className="mt-6 sm:mt-2 2xl:mt-5">
                      <div className="border-b border-red-900">
                        <div className="max-w-5xl mx-auto "></div>
                      </div>
                    </div>
                    <div className="flex justify-center place-items-start flex-wrap  md:mb-0">
                      {/* All my Post */}
                      <div className="w-full md:w-2/3 px-4 mb-4 md:mb-0">
                        <h1 className="text-center text-xl border-gray-500 mb-2 border-b-2">
                          My Post
                        </h1>
                        {/* Loop here */}
                        {profile?.posts?.length == 0 ? (
                          <h2 className="text-center text-xl text-red-400">
                            No posts found
                          </h2>
                        ) : (
                          profile?.posts.map((post) => (
                            <div
                              className="flex flex-wrap  -mx-3 mt-3  lg:mb-6"
                              key={post._id}
                            >
                              <div className="mb-2   w-full lg:w-1/4 px-3">
                                <div>
                                  <img
                                    className="object-cover h-40 rounded"
                                    src={post?.image}
                                    alt="poster"
                                  />
                                </div>
                              </div>
                              <div className="w-full lg:w-3/4 px-3">
                                <Link
                                  to={`/posts/${post?._id}`}
                                  className="hover:underline"
                                >
                                  <h3 className="mb-1 text-2xl text-red-500 font-bold font-heading">
                                    {post?.title}
                                  </h3>
                                </Link>
                                <p className="text-gray-600 truncate">
                                  {post?.description}
                                </p>

                                <Link
                                  className="text-indigo-500 hover:underline"
                                  to={`/posts/${post?._id}`}
                                >
                                  {" "}
                                  <p className="font-semibold">Read more</p>
                                </Link>
                              </div>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  </article>
                </main>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./components/Homepage/Homepage";
import Register from "./components/Users/Register/Register";
import Login from "./components/Users/Login/Login";
import Navbar from "./components/NavBar/Navbar";
import AddNewCategory from "./components/Categories/AddNewCategory";
import CategoryList from "./components/Categories/CategoryList";
import UpdateCategory from "./components/Categories/UpdateCategory";
import PrivateProtectedRoute from "./components/NavBar/ProtectedRoutes/PrivateProtectedRoute";
import AdminProtectedRoute from "./components/NavBar/ProtectedRoutes/AdminProtectedRoute";
import CreatePost from "./components/Posts/CreatePost";
import PostsList from "./components/Posts/PostLists";
import PostDetails from "./components/Posts/PostDetails";
import UpdatePost from "./components/Posts/UpdatePost";
import UpdateComment from "./components/Comments/UpdateComment";
import Profile from "./components/Users/Profile/Profile";
import UploadProfilePhoto from "./components/Users/Profile/UploadProfilePhoto";
import UpdateProfileForm from "./components/Users/Profile/UpdateProfileForm";
import SendEmail from "./components/Users/Emailing/SendEmail";
import AccountVerified from "./components/Users/AccountVerification/AccountVerified";
import UsersList from "./components/Users/UsersList/UsersList";
import UpdatePassword from "./components/Users/PasswordManagement/UpdatePassword";
import ResetPasswordForm from "./components/Users/PasswordManagement/ResetPasswordForm";
import ResetPassword from "./components/Users/PasswordManagement/ResetPassword";
import AboutMe from "./components/AboutMe/AboutMe";

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<HomePage />}></Route>
        <Route exact path="/register" element={<Register />}></Route>
        <Route exact path="/login" element={<Login />}></Route>
        <Route exact path="/posts" element={<PostsList />}></Route>
        <Route exact path="/posts/:id" element={<PostDetails />}></Route>
        <Route
          exact
          path="/password-reset-token"
          element={<ResetPasswordForm />}
        ></Route>
        <Route
          exact
          path="/reset-password/:token"
          element={<ResetPassword />}
        ></Route>
        <Route exact path="/about-me" element={<AboutMe />}></Route>

        <Route
          exact
          path="/add-category"
          element={<AdminProtectedRoute component={AddNewCategory} />}
        />
        <Route
          exact
          path="/category-list"
          element={<AdminProtectedRoute component={CategoryList} />}
        />
        <Route
          exact
          path="/update-category/:id"
          element={<AdminProtectedRoute component={UpdateCategory} />}
        />

        <Route
          exact
          path="/create-post"
          element={<AdminProtectedRoute component={CreatePost} />}
        />

        <Route
          exact
          path="/update-post/:id"
          element={<AdminProtectedRoute component={UpdatePost} />}
        />

        <Route
          exact
          path="/send-email"
          element={<AdminProtectedRoute component={SendEmail} />}
        />

        <Route
          exact
          path="/users"
          element={<AdminProtectedRoute component={UsersList} />}
        />

        <Route
          exact
          path="/update-comment/:id"
          element={<PrivateProtectedRoute component={UpdateComment} />}
        />

        <Route
          exact
          path="/profile/:id"
          element={<PrivateProtectedRoute component={Profile} />}
        />

        <Route
          exact
          path="/upload-photo/:id"
          element={<PrivateProtectedRoute component={UploadProfilePhoto} />}
        />

        <Route
          exact
          path="/update-profile/:id"
          element={<PrivateProtectedRoute component={UpdateProfileForm} />}
        />

        <Route
          exact
          path="/verify-account/:token"
          element={<PrivateProtectedRoute component={AccountVerified} />}
        />

        <Route
          exact
          path="/update-password"
          element={<PrivateProtectedRoute component={UpdatePassword} />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

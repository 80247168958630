import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  updateCommentAction,
  fetchCommentAction,
} from "../../redux/slices/comments/commentSlices";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { isEdited, removeEdited } from "../../utility/postEdited";

//Form schema
const formSchema = Yup.object({
  description: Yup.string().required("Comment cannot be empty"),
});

const UpdateComment = () => {
  const id = useParams().id;

  //dispatch
  const dispatch = useDispatch();

  //select comment from store
  const comment = useSelector((state) => state?.comment);
  const postId = useSelector((state) => state?.post?.postDetails?._id);
  const { commentDetails, isCommentUpdated } = comment;

  //fetch comments
  useEffect(() => {
    dispatch(fetchCommentAction(id));
  }, [dispatch, id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      description: removeEdited(commentDetails?.description),
    },
    onSubmit: (values) => {
      const data = {
        id,
        description: values?.description,
      };

      if (!isEdited(data?.description)) {
        data.description += " (edited)";
      }

      //dispatch action
      dispatch(updateCommentAction(data));
    },
    validationSchema: formSchema,
  });

  //redirect
  if (isCommentUpdated) return <Navigate to={`/posts/${postId}`} />;
  return (
    <div className="h-screen flex justify-center items-center bg-black">
      <div className="pt-5 pr-10 pl-10 pb-10 flex flex-col justify-center items-center  bg-gray-700">
        <form onSubmit={formik.handleSubmit} className="mt-1 flex  m-auto">
          <input
            onBlur={formik.handleBlur("description")}
            value={formik.values.description}
            onChange={formik.handleChange("description")}
            type="text"
            name="text"
            id="text"
            className="shadow-sm focus:ring-blue-500  mr-2 focus:border-blue-500 block w-full p-2 border-1 lg:text-lg border-black rounded-md"
            placeholder="Update comment"
          />

          <button
            type="submit"
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Submit
          </button>
        </form>
        <div className="text-red-400 mb-2 mt-2">
          {formik.touched.description && formik.errors.description}
        </div>
      </div>
    </div>
  );
};

export default UpdateComment;

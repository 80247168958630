import React, { useEffect } from "react";
import { useFormik, validateYupSchema } from "formik";
import { Navigate, Redirect, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import CategoryDropDown from "../Categories/CategoryDropDown";
import {
  fetchPostDetailsAction,
  updatePostAction,
} from "../../redux/slices/posts/postSlices";

//Validation
const formSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
  category: Yup.object().required("Category is required"),
});

export default function UpdatePost(props) {
  const id = useParams()?.id;

  //Fetch the post in the url
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPostDetailsAction(id));
  }, [id, dispatch]);

  //select post
  const postData = useSelector((state) => state.post);
  const { postDetails } = postData;

  //select updated post from store
  const postUpdate = useSelector((state) => state.post);
  const { loading, appErr, serverErr, isUpdated } = postUpdate;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: postDetails?.title,
      description: postDetails?.description,
      category: "",
    },
    onSubmit: (values) => {
      const data = {
        title: values.title,
        description: values.description,
        category: values?.category?.label,
        id,
      };
      dispatch(updatePostAction(data));
    },

    validationSchema: formSchema,
  });

  //redirect
  if (isUpdated) return <Navigate to="/posts" />;

  return (
    <>
      <div className="min-h-screen bg-black flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-300">
            Are you sure you want to edit{" "}
            <span className="text-cyan-500">{postDetails?.title}</span> ?
          </h2>
          {appErr || serverErr ? (
            <h1 className="text-red-400 text-lg text-center">
              {appErr} {serverErr}
            </h1>
          ) : null}
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form onSubmit={formik.handleSubmit} className="space-y-6">
              <div>
                <label
                  htmlFor="Title"
                  className="block text-sm font-medium text-gray-700"
                >
                  Title
                </label>
                <div className="mt-1">
                  <input
                    id="title"
                    name="title"
                    type="title"
                    autoComplete="title"
                    onBlur={formik.handleBlur("title")}
                    value={formik.values.title}
                    onChange={formik.handleChange("title")}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
                <div className="text-red-500">
                  {formik.touched.title && formik.errors.title}
                </div>
              </div>

              <label
                htmlFor="Category"
                className="block text-sm font-medium text-gray-700"
              >
                Category
              </label>
              <CategoryDropDown
                value={formik.values.category?.categoryTitle}
                onChange={formik.setFieldValue}
                onBlur={formik.setFieldTouched}
                error={formik.errors.category}
                touched={formik.touched.category}
              />
              <div className="text-red-500">
                {formik.touched.category && formik.errors.category}
              </div>
              <div>
                <label
                  htmlFor="Description"
                  className="block text-sm font-medium text-gray-700"
                >
                  Description
                </label>
                <textarea
                  rows="5"
                  cols="10"
                  onBlur={formik.handleBlur("description")}
                  value={formik.values.description}
                  onChange={formik.handleChange("description")}
                  className="whitespace-pre-line rounded-lg appearance-none block w-full py-3 px-3 text-base text-left leading-tight text-gray-600 bg-transparent focus:bg-transparent  border border-gray-200 focus:border-blue-500  focus:outline-none"
                  type="text"
                ></textarea>
                <div className="text-red-500">
                  {formik.touched.description && formik.errors.description}
                </div>
              </div>

              <div>
                {loading ? (
                  <button
                    disabled
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-500"
                  >
                    Loading please wait...
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Update
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

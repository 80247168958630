import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const PrivateProtectedRoute = ({ component: Component, ...rest }) => {
  //check if the user is logged in
  const user = useSelector((state) => state?.users);
  const { userAuth } = user;
  return userAuth ? <Component {...rest} /> : <Navigate to="/login" />;
};

export default PrivateProtectedRoute;

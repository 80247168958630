//Backend Domain URL
//**DEBUG**
// const API_DOMAIN_URL = "http://localhost:5000";
//PROD
const API_DOMAIN_URL =
  "https://thayiland-backend-services.herokuapp.com";

//users
const API_REGISTER_URL = "api/users/register";
const API_LOGIN_URL = "api/users/login";
const API_GETPROFILE_URL = "api/users/profile";
const API_UPDATEPROFILEPHOTO = "api/users/profilephoto-upload";
const API_UPDATEUSERPROFILE = "api/users";
const API_GETUSERPROFILEDATA = "api/users";
const API_GENERATEVERIFYTOKEN = "api/users/generate-verify-email-token";
const API_VERIFYACCOUNT = "api/users/verify-account";
const API_BLOCKUSER = "api/users/block-user";
const API_UNBLOCKUSER = "api/users/unblock-user";
const API_DELETEUSER = "api/users";
const API_UPDATEPASSWORD = "api/users/password";
const API_FORGETPASSWORDTOKEN = "api/users/forget-password-token";
const API_RESETPASSWORD = "api/users/reset-password";

//email
const API_SENDEMAIL = "api/email";

//category
const API_ADDCATEGORY_URL = "api/category";
const API_FETCHALLCATEGORY_URL = "api/category";
const API_UPDATECATEGORY_URL = "api/category";
const API_DELETECATEGORY_URL = "api/category";
const API_FETCHCATEGORY_URL = "api/category";

//post
const API_CREATEPOST_URL = "api/posts";
const API_GETALLPOSTS_URL = "api/posts";
const API_TOGGLELIKE_URL = "api/posts/likes";
const API_TOGGLEDISLIKE_URL = "api/posts/dislikes";
const API_UPDATEPOST_URL = "api/posts";
const API_DELETEPOST_URL = "api/posts";

//comment
const API_ADDCOMMENT_URL = "api/comments";
const API_DELETECOMMENT_URL = "api/comments";
const API_UPDATECOMMENT_URL = "api/comments";
const API_GETACOMMENT_URL = "api/comments";

export {
  API_DOMAIN_URL,
  API_REGISTER_URL,
  API_LOGIN_URL,
  API_GETPROFILE_URL,
  API_UPDATEPROFILEPHOTO,
  API_UPDATEUSERPROFILE,
  API_GETUSERPROFILEDATA,
  API_GENERATEVERIFYTOKEN,
  API_VERIFYACCOUNT,
  API_BLOCKUSER,
  API_UNBLOCKUSER,
  API_DELETEUSER,
  API_UPDATEPASSWORD,
  API_FORGETPASSWORDTOKEN,
  API_RESETPASSWORD,
  API_SENDEMAIL,
  API_ADDCATEGORY_URL,
  API_FETCHALLCATEGORY_URL,
  API_UPDATECATEGORY_URL,
  API_DELETECATEGORY_URL,
  API_FETCHCATEGORY_URL,
  API_CREATEPOST_URL,
  API_GETALLPOSTS_URL,
  API_TOGGLELIKE_URL,
  API_TOGGLEDISLIKE_URL,
  API_UPDATEPOST_URL,
  API_DELETEPOST_URL,
  API_ADDCOMMENT_URL,
  API_DELETECOMMENT_URL,
  API_UPDATECOMMENT_URL,
  API_GETACOMMENT_URL,
};

import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { Link, useParams, Navigate } from "react-router-dom";
import {
  deletePostAction,
  fetchPostDetailsAction,
} from "../../redux/slices/posts/postSlices";
import { useDispatch, useSelector } from "react-redux";
import DateFormatter from "../../utility/DateFormatter";
import LoadingSpinner from "../../utility/LoadingSpinner";
import AddComment from "../Comments/AddComment";
import CommentsList from "../Comments/CommentsList";

const PostDetails = () => {
  const { id } = useParams();

  //select post details from store
  const post = useSelector((state) => state?.post);

  const { postDetails, loading, appErr, serverErr, isDeleted } = post;

  const dispatch = useDispatch();

  //comments
  const comment = useSelector((state) => state?.comment);
  const { commentCreated, commentDeleted } = comment;

  useEffect(() => {
    dispatch(fetchPostDetailsAction(id));
  }, [id, dispatch, commentCreated, commentDeleted]);

  //Get login user
  const user = useSelector((state) => state?.users);

  const isAdmin = user?.userAuth?.isAdmin;

  const isAuthor = user?.userAuth?._id === postDetails?.user?._id;

  //redirect
  if (isDeleted) return <Navigate to="/posts" />;

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : appErr || serverErr ? (
        <h1 className="text-red-500 text-xl">
          {appErr} {serverErr}
        </h1>
      ) : (
        <section className="py-20 2xl:py-40 bg-black overflow-hidden">
          <div className="container px-4 mx-auto">
            {/* Post Image */}
            <img
              className="mb-24 w-full h-96 object-cover"
              src={postDetails?.image}
              alt=""
            />
            <div className="max-w-2xl mx-auto text-center">
              <h2 className="mt-7 mb-14 text-6xl 2xl:text-7xl text-white font-bold font-heading">
                {postDetails?.title}
              </h2>

              {/* User */}
              <div className="inline-flex pt-14 mb-14 items-center border-t border-gray-500">
                <img
                  className="mr-8 w-20 lg:w-24 h-20 lg:h-24 rounded-full"
                  src={postDetails?.user?.profilePhoto}
                  alt=""
                />
                <div className="text-left">
                  <h4 className="mb-1 text-2xl font-bold text-gray-50">
                    <span className="text-xl lg:text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-br from-cyan-400 to-purple-500">
                      {postDetails?.user?.firstName}{" "}
                      {postDetails?.user?.lastName}
                    </span>
                  </h4>
                  <p className="text-gray-500">
                    <DateFormatter date={postDetails?.createdAt} />
                  </p>
                </div>
              </div>
              {/* Post description */}
              <div className="max-w-xl mx-auto">
                <div className="whitespace-pre-line mb-6 text-left  text-xl text-gray-200">
                  {postDetails?.description}
                </div>

                {/* Show delete and update btn only if user is Admin */}
                {isAdmin && isAuthor ? (
                  <p className="flex">
                    <Link to={`/update-post/${postDetails?._id}`}>
                      <button className="mr-9" type="submit">
                        <FontAwesomeIcon
                          icon={faPenToSquare}
                          size="lg"
                          className="text-yellow-300 mt-7"
                        />
                      </button>
                    </Link>

                    <button
                      onClick={() =>
                        dispatch(deletePostAction(postDetails?._id))
                      }
                      className="ml-9"
                    >
                      <FontAwesomeIcon
                        icon={faTrashCan}
                        size="lg"
                        className="text-red-500 mt-7"
                      />
                    </button>
                  </p>
                ) : (
                  <div />
                )}
              </div>
            </div>
          </div>
          {/* Add comment Form component here */}
          {user?.userAuth ? (
            <AddComment postId={id} />
          ) : (
            <p className="text-gray-500 text-center">
              <strong>
                (Please{" "}
                <a className="text-yellow-400 hover:underline" href="/register">
                  register
                </a>{" "}
                to comment here)
              </strong>
            </p>
          )}

          <div className="flex justify-center  items-center text-white">
            {/* <CommentsList comments={post?.comments} postId={post?._id} /> */}

            <CommentsList comments={postDetails?.comments} />
          </div>
        </section>
      )}
    </>
  );
};

export default PostDetails;

import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbsDown,
  faThumbsUp,
  faEye,
} from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";
import {
  fetchAllPostsAction,
  toggleLikeToPost,
  toggleDislikeToPost,
} from "../../redux/slices/posts/postSlices";
import { useDispatch, useSelector } from "react-redux";
import DateFormatter from "../../utility/DateFormatter";
import { fetchAllCategoriesAction } from "../../redux/slices/category/categorySlice";
// import { LoadingSpinner } from "../../utility/LoadingSpinner";
import LoadingSpinner from "../../utility/LoadingSpinner";

export default function PostsList() {
  //dispatch
  const dispatch = useDispatch();

  //select post from storeData
  const posts = useSelector((state) => state?.post);

  //select categories from storeData
  const category = useSelector((state) => state?.category);

  const { postLists, loading, appErr, serverErr, likes, dislikes } = posts;

  //fetch categories
  useEffect(() => {
    dispatch(fetchAllCategoriesAction());
  }, [dispatch]);

  //fetch post
  useEffect(() => {
    dispatch(fetchAllPostsAction());
  }, [dispatch, likes, dislikes]);

  const {
    categoryList,
    loading: categoryLoading,
    appErr: categoryAppErr,
    serverErr: categoryServerErr,
  } = category;

  return (
    <>
      <section>
        <div className="py-20 bg-black min-h-screen radius-for-skewed">
          <div className="container mx-auto px-4">
            <div className="mb-16 flex flex-wrap items-center">
              <div className="w-full lg:w-1/2">
                <h2 className="text-4xl text-gray-300 lg:text-5xl font-bold font-heading">
                  Latest Post
                </h2>
              </div>
              <div className=" block text-right w-1/2">
                {/* View All */}
                <button
                  onClick={() => dispatch(fetchAllPostsAction())}
                  className="inline-block mt-4 py-2 px-6 rounded-l-xl rounded-t-xl bg-red-500 hover:bg-red-700 text-gray-50 font-bold leading-loose transition duration-200"
                >
                  View All Posts
                </button>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3">
              <div className="mb-8 lg:mb-0 w-full lg:w-1/4 px-3">
                <div className="py-4 px-6 bg-gray-900 shadow rounded">
                  <h4 className="mb-4 text-gray-300 font-bold uppercase">
                    Categories
                  </h4>
                  <ul>
                    {/* <div className="text-white">Loading...</div>

                    <div className="text-red-400 text-base">
                      Categories Error goes here
                    </div>

                    <div className="text-xl text-gray-100 text-center">
                      No category
                    </div> */}

                    {categoryLoading ? (
                      <h1 className="text-white">Loading... </h1>
                    ) : categoryAppErr || categoryServerErr ? (
                      <h1>
                        {categoryAppErr} {categoryServerErr}
                      </h1>
                    ) : categoryList?.length == 0 ? (
                      <h1 className="text-red-400">No Category found</h1>
                    ) : (
                      categoryList?.map((category) => (
                        <li key={category._id}>
                          <p
                            onClick={() =>
                              dispatch(fetchAllPostsAction(category?.title))
                            }
                            className="block cursor-pointer py-2 px-3 mb-4 text-orange-300 rounded font-bold bg-gray-700"
                          >
                            {category?.title}
                          </p>
                        </li>
                      ))
                    )}
                  </ul>
                </div>
              </div>

              {appErr || serverErr ? (
                <h1 className="text-red-400">
                  {appErr} {serverErr}
                </h1>
              ) : postLists?.length == 0 ? (
                <h1 className="text-white">No Post found</h1>
              ) : (
                postLists?.map((post) => (
                  <div key={post._id} className="w-full lg:w-3/4 px-3">
                    <div className="flex flex-wrap bg-black -mx-3  lg:mb-6">
                      <div className="mb-10  w-full lg:w-1/4">
                        <div>
                          {/* Post image */}
                          <img
                            className="w-full h-full object-cover rounded"
                            src={post?.image}
                            alt=""
                          />
                        </div>
                        {/* Likes, views dislikes */}
                        <div className="flex flex-row bg-gray-300 justify-center w-full  items-center ">
                          {/* Likes */}
                          <div className="flex flex-row justify-center items-center ml-4 mr-4 pb-2 pt-1">
                            {/* Togle like  */}
                            <div className="text-green-500">
                              {/* <ThumbUpIcon className="h-7 w-7 text-indigo-600 cursor-pointer" /> */}
                              <FontAwesomeIcon
                                onClick={() =>
                                  dispatch(toggleLikeToPost(post?._id))
                                }
                                icon={faThumbsUp}
                              />
                            </div>
                            <div className="pl-2 text-gray-600">
                              {post?.likes?.length}
                            </div>
                          </div>
                          {/* Dislike */}
                          <div className="flex flex-row  justify-center items-center ml-4 mr-4 pb-2 pt-1">
                            <div className="text-red-400">
                              {/* <ThumbDownIcon className="h-7 w-7 cursor-pointer text-gray-600" /> */}
                              <FontAwesomeIcon
                                onClick={() =>
                                  dispatch(toggleDislikeToPost(post?._id))
                                }
                                icon={faThumbsDown}
                              />
                            </div>
                            <div className="pl-2 text-gray-600">
                              {post?.dislikes?.length}
                            </div>
                          </div>
                          {/* Views */}
                          <div className="flex flex-row justify-center items-center ml-4 mr-4 pb-2 pt-1">
                            <div className="text-blue-500">
                              {/* <EyeIcon className="h-7 w-7  text-gray-400" /> */}
                              <FontAwesomeIcon icon={faEye} />
                            </div>
                            <div className="pl-2 text-gray-600">
                              {post?.numViews}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-full lg:w-3/4 px-3">
                        <div className="hover:underline">
                          <h3 className="mb-1 text-2xl font-bold font-heading">
                            <font color="#1099cc">
                              <a href={`/posts/${post?._id}`}>{post?.title} </a>
                            </font>
                          </h3>
                        </div>
                        <p className="text-sm text-gray-400">
                          <i>{post?.category}</i>
                        </p>
                        <p
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            width: "200px",
                            height: "100px",
                            whiteSpace: "nowrap",
                          }}
                          className="text-gray-300"
                        >
                          {post?.description}
                        </p>
                        {/* Read more */}
                        <div className="text-red-400 hover:underline">
                          <a href={`/posts/${post?._id}`}>Read More..</a>
                        </div>

                        {/* User Avatar */}
                        <div className="mt-6 mb-6 flex items-center">
                          <div className="flex-shrink-0">
                            <div>
                              <img
                                className="h-10 w-10 rounded-full"
                                src={post?.user?.profilePhoto}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="ml-3">
                            <div className="text-sm font-medium text-gray-900">
                              <div className="text-white hover:underline ">
                                {post?.user?.firstName} {post?.user?.lastName}
                              </div>
                            </div>
                            <div className="flex space-x-1 text-sm text-gray-400">
                              <DateFormatter date={post?.createdAt} />

                              {/* <span aria-hidden="true">&middot;</span> */}
                            </div>
                          </div>
                        </div>
                        {/* <p class="text-gray-500">
                          Quisque id sagittis turpis. Nulla sollicitudin rutrum
                          eros eu dictum...
                        </p> */}
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

import { createAsyncThunk, createSlice, createAction } from "@reduxjs/toolkit";
import axios from "axios";
import {
  API_DOMAIN_URL,
  API_GENERATEVERIFYTOKEN,
  API_VERIFYACCOUNT,
} from "../../../utility/backendAPILinks";

//redirect action
const resetAccount = createAction("account/verify-reset");

//create verification token
export const accountVerificationTokenAction = createAsyncThunk(
  "account/token",
  async (email, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;

    const { userAuth } = user;

    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };

    //http call
    try {
      const { data } = await axios.post(
        `${API_DOMAIN_URL}/${API_GENERATEVERIFYTOKEN}`,
        {},
        config
      );

      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

//verify account
export const verifyAccountAction = createAsyncThunk(
  "account/verify",
  async (token, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;

    const { userAuth } = user;

    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };

    //http call
    try {
      const { data } = await axios.put(
        `${API_DOMAIN_URL}/${API_VERIFYACCOUNT}`,
        { token },
        config
      );

      //dispatch
      dispatch(resetAccount);

      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

//slices
const accountVerificationSlices = createSlice({
  name: "account",
  initialState: {},
  extraReducers: (builder) => {
    //create
    builder.addCase(accountVerificationTokenAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(
      accountVerificationTokenAction.fulfilled,
      (state, action) => {
        state.tokenSent = action?.payload;
        state.loading = false;
        state.appErr = undefined;
        state.serverErr = undefined;
      }
    );
    builder.addCase(
      accountVerificationTokenAction.rejected,
      (state, action) => {
        state.loading = false;
        state.appErr = action?.payload?.message;
        state.serverErr = action?.error?.message;
      }
    );

    //verify
    builder.addCase(verifyAccountAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(resetAccount, (state, action) => {
      state.isVerified = true;
    });

    builder.addCase(verifyAccountAction.fulfilled, (state, action) => {
      state.verified = action?.payload;
      state.isVerified = false;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(verifyAccountAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
  },
});

export default accountVerificationSlices.reducer;

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLock,
  faLockOpen,
  faUser,
  faBan,
  faSquareCheck,
  faQuestionCircle,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import {
  blockUserAction,
  unblockUserAction,
  deleteUserAction,
} from "../../../redux/slices/users/usersSlices";
import { useDispatch, useSelector } from "react-redux";

const UsersListItem = (user) => {
  //dispatch
  const dispatch = useDispatch();

  //History
  const history = useNavigate();

  //send Email Handle click
  const sendMailNavigate = () => {
    history("/send-email", {
      state: { email: user?.user?.email, id: user?.user?._id },
    });
  };

  return (
    <>
      <div className="p-8 mb-4 bg-white shadow rounded">
        <div className="flex flex-wrap items-center -mx-4">
          <div className="w-full lg:w-3/12 flex px-4 mb-6 lg:mb-0">
            <img
              className="w-10 h-10 mr-4 object-cover rounded-full"
              src={user?.user?.profilePhoto}
              alt="profile "
            />
            <div>
              <p className="text-sm font-medium">
                {user?.user?.firstName} {user?.user?.lastName}
              </p>
              {user?.user?.isAccountVerified ? (
                <div className="text-blue-500">
                  <FontAwesomeIcon icon={faSquareCheck} /> {" Verified"}
                </div>
              ) : (
                <div className="text-yellow-500">
                  <FontAwesomeIcon icon={faQuestionCircle} />
                  {" Unverified"}
                </div>
              )}
              <p className="text-xs text-gray-500">{user?.user?.email}</p>
            </div>
          </div>
          <div className="w-1/4 lg:w-1/12 px-2 mb-2 lg:mb-0">
            {user?.user?.isAdmin ? (
              <p className="py-1 px-2 text-xs bg-yellow-100 text-yellow-800 rounded-full">
                <span>
                  <b>Admin</b>
                </span>{" "}
              </p>
            ) : null}
            {/* <span>{user?.user?.isBlocked && "Blocked"}</span> */}
          </div>

          <div className="w-1/4 lg:w-1/12 px-2 mb-2 lg:mb-0">
            {user?.user?.isBlocked ? (
              <p className="py-1 px-2 text-xs bg-red-500 text-white rounded-full">
                <span>
                  <FontAwesomeIcon icon={faBan} />
                </span>
                <b>{" Blocked "}</b>
              </p>
            ) : null}
            {/* <span>{user?.user?.isBlocked && "Blocked"}</span> */}
          </div>

          <div className="w-full flex lg:w-4/12 px-4  mb-6 lg:mb-0">
            <Link
              to={`/profile/${user?.user?._id}`}
              className=" inline-block py-1 px-2 text-center bg-yellow-500 mr-2 mb-1 lg:mb-0 text-xs border rounded  hover:bg-cyan-400"
            >
              <span className="text-white text-center">
                {" "}
                <FontAwesomeIcon icon={faUser} />
                <b> Profile</b>
              </span>
            </Link>

            {user?.user?.isBlocked ? (
              <button
                onClick={() => dispatch(unblockUserAction(user?.user?._id))}
                className="inline-block py-1 px-2  bg-green-500 text-white mr-2 mb-1 lg:mb-0 text-xs border rounded hover:bg-cyan-400"
              >
                <FontAwesomeIcon icon={faLockOpen} />
                <b> Unblock</b>
              </button>
            ) : (
              <button
                onClick={() => dispatch(blockUserAction(user?.user?._id))}
                className="inline-block py-1 px-2  bg-red-500 text-white mr-2 mb-1 lg:mb-0 text-xs border rounded hover:bg-cyan-400"
              >
                <FontAwesomeIcon icon={faLock} />

                <b> Block</b>
              </button>
            )}

            <button
              onClick={sendMailNavigate}
              to={`/send-email?email=${user?.user?.email}`}
              className="inline-block py-1 px-2 text-center bg-yellow-500 text-white mr-2 mb-1 lg:mb-0 text-xs border rounded  hover:bg-cyan-400"
            >
              <FontAwesomeIcon
                icon={faEnvelope}
                className="ml-1 mr-2 h-3 w-3"
                aria-hidden="true"
              />
              <span className="text-white text-center">
                <b>Message</b>
              </span>
            </button>

            {!user?.user?.isAdmin ? (
              <button
                onClick={() => dispatch(deleteUserAction(user?.user?._id))}
                className="inline-block py-1 px-2 text-center bg-red-500 text-white mr-2 mb-1 lg:mb-0 text-xs border rounded  hover:bg-cyan-400"
              >
                <FontAwesomeIcon
                  icon={faTrashCan}
                  className="ml-1 mr-2 h-3 w-3"
                  aria-hidden="true"
                />
                <span className="text-white text-center">
                  <b>Delete user</b>
                </span>
              </button>
            ) : null}
          </div>
          <div className="w-full lg:w-1/12 px-4">
            <div className="flex items-center">
              {/* Send Mail */}
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UsersListItem;

function isEdited(text) {
  if (text != undefined) {
    var words = text?.split(" ");
    return words[words.length - 1] == "(edited)";
  }
}

function removeEdited(text) {
  if (isEdited(text)) {
    var lastIndex = text.lastIndexOf(" ");
    var commentTobeDisplayed = text.substring(0, lastIndex);
    return commentTobeDisplayed;
  }
  return text;
}
export { isEdited, removeEdited };

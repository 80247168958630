import React from "react";
import { useSelector } from "react-redux";
import PrivateNavBar from "./Private/PrivateNavbar";
import PublicNavBar from "./Public/PublicNavbar";
import AdminNavBar from "./Admin/AdminNavBar";
import AccountVerificationAlertWarning from "./Alerts/AccountVerificationAlertWarning";
import AccountVerificationSuccessAlert from "./Alerts/AccountVerificationSuccessAlert";

const Navbar = () => {
  const state = useSelector((state) => state.users);
  const accountVerifyState = useSelector((state) => state.accountVerification);
  const { userAuth, profile } = state;
  const isAdmin = userAuth?.isAdmin;
  const { loading, appErr, serverErr, tokenSent } = accountVerifyState;

  return (
    <div>
      {isAdmin ? (
        <AdminNavBar isLogin={userAuth} />
      ) : userAuth ? (
        <PrivateNavBar isLogin={userAuth} />
      ) : (
        <PublicNavBar />
      )}
      {userAuth && !userAuth.isVerified && <AccountVerificationAlertWarning />}
      {loading && (
        <h2 className="text-center text-black text-lg">
          <b> Loading please wait...</b>
        </h2>
      )}
      {tokenSent && <AccountVerificationSuccessAlert />}
      {appErr || serverErr ? (
        <h2 className="text-center text-red-500 text-lg">
          {appErr} {serverErr}
        </h2>
      ) : null}
    </div>
  );
};

export default Navbar;

import { css } from "@emotion/react";
import RiseLoader from "react-spinners/CircleLoader";

//css
const override = css`
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  `;

const LoadingSpinner = () => {
  return (
    <div className="grid h-screen place-items-center">
      <RiseLoader color="red" loading={true} css={override} />
    </div>
  );
};

// const LoadingSpinner = () => {
//     return (
//             <div className="grid h-screen place-items-center"><SpinnerCircular/></div>
//     )
// }

export default LoadingSpinner;

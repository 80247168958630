import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { createPostAction } from "../../redux/slices/posts/postSlices";
import CategoryDropDown from "../Categories/CategoryDropDown";
import props from "prop-types";
import Dropzone from "react-dropzone";
import { Navigate } from "react-router-dom";
import styled from "styled-components";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";

//Form schema
const formSchema = Yup.object({
  title: Yup.string().required("Post title is required"),
  description: Yup.string().required("Post description is required"),
  category: Yup.object().required("Post category is required"),
  // image: Yup.object().required("Image is required"),
});

//css for dropzone
const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  border-color: "red";
  transition: border 0.24s ease-in-out;
`;

export default function CreatePost() {
  //
  const [imageUrl, setImageUrl] = useState("");

  const dispatch = useDispatch();
  const userId = useSelector((state) => state?.users?.userAuth?._id);

  //select store data
  const post = useSelector((state) => state.post);
  const { isCreated, loading, appErr, serverErr } = post;

  //formik
  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      user: "",
      category: "",
      image: "",
    },
    onSubmit: (values) => {
      // console.log(values)
      //dispath the action
      const data = {
        category: values?.category?.label,
        title: values?.title,
        description: values?.description,
        user: userId,
        image: values?.image,
      };
      dispatch(createPostAction(data));
    },
    validationSchema: formSchema,
  });

  //redirect
  if (isCreated) return <Navigate to="/posts"></Navigate>;

  return (
    <>
      <div className="min-h-screen bg-black flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-300">
            <b>Create Post</b>
          </h2>

          <p className="text-center font-medium">
            <font color="#1099cc"> Share your ideas to the world.</font>
          </p>

          {appErr || serverErr ? (
            <p className="text-center font-medium text-red-500">
              {appErr} {serverErr}
            </p>
          ) : null}
        </div>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form onSubmit={formik.handleSubmit} className="space-y-6">
              <div>
                <label
                  htmlFor="Title"
                  className="block text-sm font-medium text-gray-700"
                >
                  <b>Title</b>
                </label>
                <div className="mt-1">
                  {/* Title */}
                  <input
                    value={formik.values.title}
                    onChange={formik.handleChange("title")}
                    onBlur={formik.handleBlur("title")}
                    id="title"
                    name="title"
                    type="title"
                    autoComplete="title"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
                {/* Err msg */}
                <div className="text-red-500">
                  {formik.touched.title && formik.errors.title}
                </div>
              </div>
              <label className="block text-sm font-medium text-gray-700">
                <b>Category</b>
              </label>
              <CategoryDropDown
                value={formik.values.category?.label}
                onChange={formik.setFieldValue}
                onBlur={formik.setFieldTouched}
                error={formik.errors}
                touched={formik.touched.category}
              />
              {/* Err msg */}
              <div className="text-red-500">
                {formik.touched.category && formik.errors?.category && (
                  <p>{formik.errors?.category}</p>
                )}
              </div>

              <div>
                <label
                  htmlFor="Description"
                  className="block text-sm font-medium text-gray-700"
                >
                  <b> Description</b>
                </label>
                {/* Description */}
                <textarea
                  value={formik.values.description}
                  onChange={formik.handleChange("description")}
                  onBlur={formik.handleBlur("description")}
                  rows="5"
                  cols="10"
                  className="rounded-lg appearance-none block w-full py-3 px-3 text-base text-left leading-tight text-gray-600 bg-transparent focus:bg-transparent  border border-gray-200 focus:border-blue-500  focus:outline-none"
                  type="text"
                ></textarea>
                {/* Err msg */}
                <div className="text-red-500">
                  {formik.touched.description && formik.errors.description}
                </div>
              </div>
              {/* Image component*/}
              <div>
                <label
                  htmlFor="image"
                  className="block text-sm font-medium text-gray-700"
                >
                  <b>Image upload</b>
                  <Container className="container bg-gray-600">
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        formik.setFieldValue("image", acceptedFiles[0]);
                        setImageUrl(acceptedFiles[0].path);
                      }}
                      accept="image/jpeg, image/png, image/JPG"
                      onBlur={formik.handleBlur("image")}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>Click here to select files</p>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </Container>
                </label>
              </div>
              <div>
                <p>
                  <b>Post image :</b>{" "}
                  <i>{imageUrl ? imageUrl : "not selected"} </i>
                  <b className={imageUrl ? "text-green-400" : "text-red-500"}>
                    {imageUrl ? (
                      <FontAwesomeIcon icon={faCheck} />
                    ) : (
                      <FontAwesomeIcon icon={faXmark} />
                    )}
                  </b>
                </p>
              </div>

              <div>
                {/* Submit btn */}
                {loading ? (
                  <button
                    disabled
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2"
                  >
                    Loading please wait...
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Create
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

import { configureStore } from "@reduxjs/toolkit";
import usersReducer from "../slices/users/usersSlices";
import categoriesReducer from "../slices/category/categorySlice";
import postReducer from "../slices/posts/postSlices";
import commentReducer from "../slices/comments/commentSlices";
import sendEmailReducer from "../slices/email/emailSlices";
import accountVerificationReducer from "../slices/accountVerification/accountVerifySlices";

const store = configureStore({
  reducer: {
    users: usersReducer,
    category: categoriesReducer,
    post: postReducer,
    comment: commentReducer,
    sendEmail: sendEmailReducer,
    accountVerification: accountVerificationReducer,
  },
});

export default store;

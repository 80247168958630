import { Link } from "react-router-dom";

import Moment from "react-moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { deleteCommentAction } from "../../redux/slices/comments/commentSlices";

export default function CommentsList({ comments }) {
  var latest_comments = [];
  if (comments != undefined) {
    var latest_comments = [...comments].reverse();
  }

  //Get login user
  const user = useSelector((state) => state?.users);
  const isAdmin = user?.userAuth?.isAdmin;

  const dispatch = useDispatch();

  function userColor(isCommentFromAdmin) {
    var color = "#3cdde5";
    if (isCommentFromAdmin) {
      color = "#f44336";
    }
    return color;
  }

  return (
    <div>
      <ul className="divide-y bg-gray-700 w-96 divide-gray-200 p-3 mt-5">
        <div className="text-gray-400">
          Comments (
          {latest_comments?.length > 0 ? latest_comments?.length : "0"})
        </div>
        <>
          {latest_comments?.length <= 0 ? (
            <h1 className="text-yellow-400 text-lg text-center">No comments</h1>
          ) : (
            latest_comments?.map((comment) => (
              <li key={comment._id} className="py-4  w-full">
                <div className="flex space-x-3">
                  <img
                    className="h-6 w-6 rounded-full"
                    src={comment?.user?.profilePhoto}
                    alt=""
                  />
                  <div className="flex-1 space-y-1">
                    <div className="flex items-center justify-between">
                      <h3
                        className="text-sm font-medium"
                        style={{
                          color: userColor(comment?.user?.isAdmin),
                        }}
                      >
                        {comment?.user?.firstName} {comment?.user?.lastName}
                      </h3>
                      <p className="text-bold text-yellow-500 text-base ml-5">
                        <Moment fromNow ago>
                          {comment?.createdAt}
                        </Moment>
                      </p>
                    </div>
                    <p className="text-sm text-gray-400">
                      {comment?.description}
                    </p>
                    {/* Check if is the same user created this comment */}

                    {isAdmin || user?.userAuth?._id == comment?.user?._id ? (
                      <p className="flex">
                        {/* <Link class="p-3">
                          <PencilAltIcon class="h-5 mt-3 text-yellow-300" />
                        </Link>
                        <button class="ml-3">
                          <TrashIcon class="h-5 mt-3 text-red-600" />
                        </button> */}
                        <Link to={`/update-comment/${comment?._id}`}>
                          <button className="mr-10">
                            <FontAwesomeIcon
                              icon={faPenToSquare}
                              size="lg"
                              className="text-yellow-300 mt-7"
                            />
                          </button>
                        </Link>
                        <button
                          className="ml-10"
                          onClick={() =>
                            dispatch(deleteCommentAction(comment?._id))
                          }
                        >
                          <FontAwesomeIcon
                            icon={faTrashCan}
                            size="lg"
                            className="text-red-500 mt-7"
                          />
                        </button>
                      </p>
                    ) : (
                      <div />
                    )}
                  </div>
                </div>
              </li>
            ))
          )}
        </>
      </ul>
    </div>
  );
}

import React from "react";
import Select from "react-select";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchAllCategoriesAction } from "../../redux/slices/category/categorySlice";

const CategoryDropDown = (props) => {
  // dispatch action
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllCategoriesAction());
  }, [dispatch]);

  //select categories
  const category = useSelector((state) => state?.category);
  const { categoryList, loading, appErr, serverErr } = category;
  const allCategories = categoryList?.map((category) => {
    return { label: category?.title, value: category?._id };
  });

  //Handle change
  const handleChange = (value) => {
    props.onChange("category", value);
  };

  //Handle blur
  const handleBlur = () => {
    props.onBlur("category", true);
  };

  return (
    <div>
      {loading ? (
        <h3 className="text-base text-gray-500">
          Categories are loading, please wait...
        </h3>
      ) : (
        <Select
          onChange={handleChange}
          onBlur={handleBlur}
          id="category"
          value={props?.value?.label}
          options={allCategories}
        />
      )}
    </div>
  );
};

export default CategoryDropDown;
